<template>
  <iframe class="terms-iframe" src="https://www.cloakone.com/terms.html" frameborder="0"></iframe>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  mounted() {
    if (this.isAuthenticated) {
      this.$router.push({ name: "dashboard" });
    }
  },

  computed: {
    ...mapGetters([
      "isAuthenticated"
    ]),
  }
}
</script>

<style scoped>
.terms-iframe {
  min-width: 100%;
  min-height: 100vh;
}
</style>
